import React, { useState, useRef } from "react";
import "../assets/styles/Home.css";
import arrow from "../assets/images/arrow.png";
import Gabbie from "../assets/images/models/Gabbie Carter.png";
import Dillion from "../assets/images/models/Dillion Harper2.jpg";
import Felicity from "../assets/images/models/Felicity Fey2.jpg";
import Josephine from "../assets/images/models/Josephine Jackson3.jpg";
import Katerina from "../assets/images/models/Katerina Hartlova4.jpg";
import Sensual from "../assets/images/models/Sensual Jane.jpg";
import Nekane from "../assets/images/models/Nekane2.jpg";
import Hanna from "../assets/images/models/Hanna Hilton3.jpg";
import Jessica from "../assets/images/models/Jessica Robbin.jpg";
import Jaye from "../assets/images/models/Jaye.jpg";
import Faye from "../assets/images/models/Faye Reagan2.jpg";
import Melita from "../assets/images/models/Melita Suica10.jpg";
import Sarah from "../assets/images/models/Sarah Waddles.jpg";
import Mia from "../assets/images/models/Mia Bailey2.jpg";
import Alejandra from "../assets/images/models/Alejandra Guilmant.jpg";
import SarahS from "../assets/images/models/Sarah Stephens2.jpg";
import Lauren from "../assets/images/models/Lauren Summer3.jpg";
import Nadya from "../assets/images/models/Nadya Nabakova.jpg";
import Natasha from "../assets/images/models/Natasha Starr.jpg";
import Natalia from "../assets/images/models/Natalia Starr.jpg";
import Taylor from "../assets/images/models/Taylor Vixen.jpg";
import Malena from "../assets/images/models/Malena Bee3.jpg";
import Viola from "../assets/images/models/Viola Bailey4.jpg";
import Keisha from "../assets/images/models/Keisha Grey.jpg";
import Leah from "../assets/images/models/Leah Gotti4.jpg";
import Lana from "../assets/images/models/Lana Rhoades.jpg";
import Evelyn from "../assets/images/models/Evelyn Claire.jpg";
import Foxxi from "../assets/images/models/Foxxi Black.jpg";
import Marina from "../assets/images/models/Marina Visconti.jpg";
import Kitana from "../assets/images/models/Kitana Flores.jpg";
import Noelle from "../assets/images/models/Noelle Easton.jpg";
import NatashaN from "../assets/images/models/Natasha Nice.webp";
import MiaM from "../assets/images/models/Mia Melano.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Elizabeth from "../assets/images/models/Elizabeth/1.jpeg";
import Elizabeth2 from "../assets/images/models/Elizabeth/2.jpeg";
import Elizabeth3 from "../assets/images/models/Elizabeth/3.jpeg";
import Elizabeth4 from "../assets/images/models/Elizabeth/4.jpg";
import Elizabeth5 from "../assets/images/models/Elizabeth/5.jpg";
import Elizabeth6 from "../assets/images/models/Elizabeth/6.jpg";
import Valeria from "../assets/images/models/Valeria/1.jpg";
import Valeria2 from "../assets/images/models/Valeria/2.jpg";
import Valeria3 from "../assets/images/models/Valeria/3.jpg";
import Valeria4 from "../assets/images/models/Valeria/4.jpg";
import Valeria5 from "../assets/images/models/Valeria/5.jpg";
import Valeria6 from "../assets/images/models/Valeria/6.jpg";
import Gabby from "../assets/images/models/Gabby/1.jpg";
import Gabby2 from "../assets/images/models/Gabby/2.jpg";
import Gabby3 from "../assets/images/models/Gabby/3.jpg";
import Gabby4 from "../assets/images/models/Gabby/4.jpg";
import Gabby5 from "../assets/images/models/Gabby/5.jpg";
import Gabby6 from "../assets/images/models/Gabby/6.jpg";
import Avery from "../assets/images/models/Avery/1.jpg";
import Avery2 from "../assets/images/models/Avery/2.jpeg";
import Avery3 from "../assets/images/models/Avery/3.jpg";
import Avery4 from "../assets/images/models/Avery/4.jpeg";
import Avery5 from "../assets/images/models/Avery/5.jpeg";
import Avery6 from "../assets/images/models/Avery/6.jpg";
import Alma from "../assets/images/models/Alma/1.jpeg";
import Alma2 from "../assets/images/models/Alma/2.jpeg";
import Alma3 from "../assets/images/models/Alma/3.jpeg";
import Alma4 from "../assets/images/models/Alma/4.jpg";
import Alma5 from "../assets/images/models/Alma/5.jpeg";
import Alma6 from "../assets/images/models/Alma/6.jpeg";
import LuciaAnne from "../assets/images/models/Lucia-Anne/1.jpeg";
import LuciaAnne2 from "../assets/images/models/Lucia-Anne/2.jpeg";
import LuciaAnne3 from "../assets/images/models/Lucia-Anne/3.jpeg";
import LuciaAnne4 from "../assets/images/models/Lucia-Anne/4.jpeg";
import LuciaAnne5 from "../assets/images/models/Lucia-Anne/5.jpeg";
import LuciaAnne6 from "../assets/images/models/Lucia-Anne/6.jpeg";
import Devon from "../assets/images/models/Devon/1.jpg";
import Devon2 from "../assets/images/models/Devon/2.jpg";
import Devon3 from "../assets/images/models/Devon/3.jpg";
import Devon4 from "../assets/images/models/Devon/4.jpg";
import Devon5 from "../assets/images/models/Devon/5.jpg";
import Devon6 from "../assets/images/models/Devon/6.jpg";
import Reese from "../assets/images/models/Reese/1.jpg";
import Reese2 from "../assets/images/models/Reese/2.jpg";
import Reese3 from "../assets/images/models/Reese/3.jpg";
import Reese4 from "../assets/images/models/Reese/4.jpg";
import Reese5 from "../assets/images/models/Reese/5.jpg";
import Reese6 from "../assets/images/models/Reese/6.jpg";
import Lindsey from "../assets/images/models/Lindsey/1.jpg";
import Lindsey2 from "../assets/images/models/Lindsey/2.jpg";
import Lindsey3 from "../assets/images/models/Lindsey/3.jpeg";
import Lindsey4 from "../assets/images/models/Lindsey/4.jpeg";
import Lindsey5 from "../assets/images/models/Lindsey/5.jpeg";
import Lindsey6 from "../assets/images/models/Lindsey/6.jpg";
import Debra from "../assets/images/models/Debra/1.jpg";
import Debra2 from "../assets/images/models/Debra/2.jpg";
import Debra3 from "../assets/images/models/Debra/3.jpeg";
import Debra4 from "../assets/images/models/Debra/4.jpg";
import Debra5 from "../assets/images/models/Debra/5.jpg";
import Debra6 from "../assets/images/models/Debra/6.jpg";
import Tessa from "../assets/images/models/Tessa/1.jpeg";
import Tessa2 from "../assets/images/models/Tessa/2.jpeg";
import Tessa3 from "../assets/images/models/Tessa/3.jpeg";
import Tessa4 from "../assets/images/models/Tessa/4.jpeg";
import Tessa5 from "../assets/images/models/Tessa/5.jpeg";
import Tessa6 from "../assets/images/models/Tessa/6.jpeg";
import Charlie from "../assets/images/models/Charlie/1.jpeg";
import Charlie2 from "../assets/images/models/Charlie/2.jpeg";
import Charlie3 from "../assets/images/models/Charlie/3.jpg";
import Charlie4 from "../assets/images/models/Charlie/4.jpeg";
import Charlie5 from "../assets/images/models/Charlie/5.jpeg";
import Charlie6 from "../assets/images/models/Charlie/6.jpeg";
import Ellie from "../assets/images/models/Ellie/1.jpeg";
import Ellie2 from "../assets/images/models/Ellie/2.jpeg";
import Ellie3 from "../assets/images/models/Ellie/3.jpeg";
import Ellie4 from "../assets/images/models/Ellie/4.jpeg";
import Ellie5 from "../assets/images/models/Ellie/5.jpeg";
import Ellie6 from "../assets/images/models/Ellie/6.jpeg";
import Kelly from "../assets/images/models/Kelly/1.jpg";
import Kelly2 from "../assets/images/models/Kelly/2.jpg";
import Kelly3 from "../assets/images/models/Kelly/3.jpg";
import Kelly4 from "../assets/images/models/Kelly/4.jpg";
import Kelly5 from "../assets/images/models/Kelly/5.jpg";
import Kelly6 from "../assets/images/models/Kelly/6.jpg";
import Beauty from "../assets/images/models/Beauty/1.jpg";
import Beauty2 from "../assets/images/models/Beauty/2.jpg";
import Beauty3 from "../assets/images/models/Beauty/3.jpg";
import Beauty4 from "../assets/images/models/Beauty/4.jpg";
import Beauty5 from "../assets/images/models/Beauty/5.jpg";
import Beauty6 from "../assets/images/models/Beauty/6.jpg";
import Hailey from "../assets/images/models/Hailey/1.jpeg";
import Hailey2 from "../assets/images/models/Hailey/2.jpeg";
import Hailey3 from "../assets/images/models/Hailey/3.jpeg";
import Hailey4 from "../assets/images/models/Hailey/4.jpeg";
import Hailey5 from "../assets/images/models/Hailey/5.jpeg";
import Hailey6 from "../assets/images/models/Hailey/6.jpeg";
import Riley from "../assets/images/models/Riley/1.jpg";
import Riley2 from "../assets/images/models/Riley/2.jpeg";
import Riley3 from "../assets/images/models/Riley/3.jpeg";
import Riley4 from "../assets/images/models/Riley/4.jpeg";
import Riley5 from "../assets/images/models/Riley/5.jpg";
import Riley6 from "../assets/images/models/Riley/6.jpeg";
import Rizzo from "../assets/images/models/Rizzo/1.jpg";
import Rizzo2 from "../assets/images/models/Rizzo/2.jpeg";
import Rizzo3 from "../assets/images/models/Rizzo/3.jpeg";
import Rizzo4 from "../assets/images/models/Rizzo/4.jpg";
import Rizzo5 from "../assets/images/models/Rizzo/5.jpeg";
import Rizzo6 from "../assets/images/models/Rizzo/6.jpeg";
import Clary from "../assets/images/models/Clary/1.jpeg";
import Clary2 from "../assets/images/models/Clary/2.jpeg";
import Clary3 from "../assets/images/models/Clary/3.jpeg";
import Clary4 from "../assets/images/models/Clary/4.jpeg";
import Clary5 from "../assets/images/models/Clary/5.jpeg";
import Clary6 from "../assets/images/models/Clary/6.jpeg";
import Aylin from "../assets/images/models/Aylin/1.jpeg";
import Aylin2 from "../assets/images/models/Aylin/2.jpeg";
import Aylin3 from "../assets/images/models/Aylin/3.jpeg";
import Aylin4 from "../assets/images/models/Aylin/4.jpg";
import Aylin5 from "../assets/images/models/Aylin/5.jpeg";
import Aylin6 from "../assets/images/models/Aylin/6.jpeg";
import EmilyJane from "../assets/images/models/Emily-Jane/1.jpeg";
import EmilyJane2 from "../assets/images/models/Emily-Jane/2.jpeg";
import EmilyJane3 from "../assets/images/models/Emily-Jane/3.jpeg";
import EmilyJane4 from "../assets/images/models/Emily-Jane/4.jpeg";
import EmilyJane5 from "../assets/images/models/Emily-Jane/5.jpeg";
import EmilyJane6 from "../assets/images/models/Emily-Jane/6.jpeg";
import Kacie from "../assets/images/models/Kacie/1.jpeg";
import Kacie2 from "../assets/images/models/Kacie/2.jpeg";
import Kacie3 from "../assets/images/models/Kacie/3.jpeg";
import Kacie4 from "../assets/images/models/Kacie/4.jpeg";
import Kacie5 from "../assets/images/models/Kacie/5.jpeg";
import Kacie6 from "../assets/images/models/Kacie/6.jpeg";
import Bishop from "../assets/images/models/Bishop/1.jpg";
import Bishop2 from "../assets/images/models/Bishop/2.jpg";
import Bishop3 from "../assets/images/models/Bishop/3.jpg";
import Bishop4 from "../assets/images/models/Bishop/4.jpg";
import Bishop5 from "../assets/images/models/Bishop/5.jpg";
import Bishop6 from "../assets/images/models/Bishop/6.jpg";
import Nina from "../assets/images/models/Nina/1.jpg";
import Nina2 from "../assets/images/models/Nina/2.jpg";
import Nina3 from "../assets/images/models/Nina/3.jpg";
import Nina4 from "../assets/images/models/Nina/4.jpg";
import Nina5 from "../assets/images/models/Nina/5.jpg";
import Nina6 from "../assets/images/models/Nina/6.jpg";
import Francesca from "../assets/images/models/Francesca/1.jpg";
import Francesca2 from "../assets/images/models/Francesca/2.jpg";
import Francesca3 from "../assets/images/models/Francesca/3.jpg";
import Francesca4 from "../assets/images/models/Francesca/4.jpg";
import Francesca5 from "../assets/images/models/Francesca/5.jpg";
import Francesca6 from "../assets/images/models/Francesca/6.jpg";
import Marya from "../assets/images/models/Marya/1.jpg";
import Marya2 from "../assets/images/models/Marya/2.jpg";
import Marya3 from "../assets/images/models/Marya/3.jpeg";
import Marya4 from "../assets/images/models/Marya/4.jpg";
import Marya5 from "../assets/images/models/Marya/5.jpg";
import Marya6 from "../assets/images/models/Marya/6.jpg";
import Paola from "../assets/images/models/Paola/1.jpeg";
import Paola2 from "../assets/images/models/Paola/2.jpeg";
import Paola3 from "../assets/images/models/Paola/3.jpeg";
import Paola4 from "../assets/images/models/Paola/4.jpeg";
import Paola5 from "../assets/images/models/Paola/5.jpeg";
import Paola6 from "../assets/images/models/Paola/6.jpeg";
import Lottie from "../assets/images/models/Lottie/1.jpeg";
import Lottie2 from "../assets/images/models/Lottie/2.jpeg";
import Lottie3 from "../assets/images/models/Lottie/3.jpeg";
import Lottie4 from "../assets/images/models/Lottie/4.jpeg";
import Lottie5 from "../assets/images/models/Lottie/5.jpeg";
import Lottie6 from "../assets/images/models/Lottie/6.jpeg";
import Martini from "../assets/images/models/Martini/1.jpeg";
import Martini2 from "../assets/images/models/Martini/2.jpeg";
import Martini3 from "../assets/images/models/Martini/3.jpeg";
import Martini4 from "../assets/images/models/Martini/4.jpeg";
import Martini5 from "../assets/images/models/Martini/5.jpeg";
import Martini6 from "../assets/images/models/Martini/6.jpeg";
import Bambi from "../assets/images/models/Bambi/1.jpg";
import Bambi2 from "../assets/images/models/Bambi/2.jpg";
import Bambi3 from "../assets/images/models/Bambi/3.jpg";
import Bambi4 from "../assets/images/models/Bambi/4.jpg";
import Bambi5 from "../assets/images/models/Bambi/5.jpg";
import Bambi6 from "../assets/images/models/Bambi/6.jpg";
import Winter from "../assets/images/models/Winter/1.jpeg";
import Winter2 from "../assets/images/models/Winter/2.jpg";
import Winter3 from "../assets/images/models/Winter/3.jpeg";
import Winter4 from "../assets/images/models/Winter/4.jpeg";
import Winter5 from "../assets/images/models/Winter/5.jpeg";
import Larisss from "../assets/images/models/Larisss/1.jpg";
import Larisss2 from "../assets/images/models/Larisss/2.jpg";
import Larisss3 from "../assets/images/models/Larisss/3.jpg";
import Larisss4 from "../assets/images/models/Larisss/4.jpg";
import Larisss5 from "../assets/images/models/Larisss/5.jpg";
import Larisss6 from "../assets/images/models/Larisss/6.jpg";
import Cameo from "../assets/images/models/Camio/1.jpeg";
import Cameo2 from "../assets/images/models/Camio/2.jpeg";
import Cameo3 from "../assets/images/models/Camio/3.jpg";
import Cameo4 from "../assets/images/models/Camio/4.jpeg";
import Cameo5 from "../assets/images/models/Camio/5.jpeg";
import Cameo6 from "../assets/images/models/Camio/6.jpg";
import Pamela from "../assets/images/models/Pamela/1.jpg";
import Pamela2 from "../assets/images/models/Pamela/2.jpg";
import Pamela3 from "../assets/images/models/Pamela/3.jpg";
import Pamela4 from "../assets/images/models/Pamela/4.jpg";
import Pamela5 from "../assets/images/models/Pamela/5.jpg";
import Pamela6 from "../assets/images/models/Pamela/7.jpg";
import Rosaline from "../assets/images/models/Rosaline/1.jpg";
import Rosaline2 from "../assets/images/models/Rosaline/2.jpg";
import Rosaline3 from "../assets/images/models/Rosaline/3.jpg";
import Rosaline4 from "../assets/images/models/Rosaline/4.jpg";
import Rosaline5 from "../assets/images/models/Rosaline/5.jpg";
import Rosaline6 from "../assets/images/models/Rosaline/6.jpg";
import Jennifer from "../assets/images/models/Jennifer/1.jpeg";
import Jennifer2 from "../assets/images/models/Jennifer/2.jpeg";
import Jennifer3 from "../assets/images/models/Jennifer/3.jpeg";
import Jennifer4 from "../assets/images/models/Jennifer/4.jpeg";
import Jennifer5 from "../assets/images/models/Jennifer/5.jpeg";
import Jennifer6 from "../assets/images/models/Jennifer/6.jpeg";
import Mistress from "../assets/images/models/Mistress Bianca/1.jpg";
import Mistress2 from "../assets/images/models/Mistress Bianca/2.jpg";
import Mistress3 from "../assets/images/models/Mistress Bianca/3.jpg";
import Mistress4 from "../assets/images/models/Mistress Bianca/4.jpg";
import Mistress5 from "../assets/images/models/Mistress Bianca/5.jpg";
import Mistress6 from "../assets/images/models/Mistress Bianca/6.jpg";
import MistressC from "../assets/images/models/Mistress Chloe/1.jpg";
import MistressC2 from "../assets/images/models/Mistress Chloe/2.jpg";
import MistressC3 from "../assets/images/models/Mistress Chloe/3.jpg";
import MistressC4 from "../assets/images/models/Mistress Chloe/4.jpg";
import MistressC5 from "../assets/images/models/Mistress Chloe/5.jpg";
import MistressR from "../assets/images/models/Mistress Reese/1.jpg";
import MistressR2 from "../assets/images/models/Mistress Reese/2.jpg";
import MistressR3 from "../assets/images/models/Mistress Reese/3.jpg";
import MistressR4 from "../assets/images/models/Mistress Reese/4.jpg";
import MistressR5 from "../assets/images/models/Mistress Reese/5.jpg";
import MistressR6 from "../assets/images/models/Mistress Reese/6.jpg";
import MistressE from "../assets/images/models/Mistress Erika/1.jpeg";
import MistressE2 from "../assets/images/models/Mistress Erika/2.jpeg";
import MistressE3 from "../assets/images/models/Mistress Erika/3.jpeg";
import MistressE4 from "../assets/images/models/Mistress Erika/4.jpeg";
import MistressE5 from "../assets/images/models/Mistress Erika/5.jpeg";
import MistressE6 from "../assets/images/models/Mistress Erika/6.jpeg";
import MistressZ from "../assets/images/models/Mistress Zoey/1.jpg";
import MistressZ2 from "../assets/images/models/Mistress Zoey/2.jpg";
import MistressZ3 from "../assets/images/models/Mistress Zoey/3.jpg";
import MistressZ4 from "../assets/images/models/Mistress Zoey/4.jpg";
import MistressZ5 from "../assets/images/models/Mistress Zoey/5.jpg";
import MistressZ6 from "../assets/images/models/Mistress Zoey/6.jpg";
import MistressB from "../assets/images/models/Mistress Belle/1.jpg";
import MistressB2 from "../assets/images/models/Mistress Belle/2.jpg";
import MistressB3 from "../assets/images/models/Mistress Belle/3.jpg";
import MistressB4 from "../assets/images/models/Mistress Belle/4.jpg";
import MistressB5 from "../assets/images/models/Mistress Belle/5.jpg";
import MistressB6 from "../assets/images/models/Mistress Belle/6.jpg";
import MistressV from "../assets/images/models/Mistress Villa/1.jpeg";
import MistressV2 from "../assets/images/models/Mistress Villa/2.jpeg";
import MistressV3 from "../assets/images/models/Mistress Villa/3.jpeg";
import MistressV4 from "../assets/images/models/Mistress Villa/4.jpeg";
import MistressV5 from "../assets/images/models/Mistress Villa/5.jpeg";
import MistressV6 from "../assets/images/models/Mistress Villa/6.jpeg";
import MistressK from "../assets/images/models/Mistress Keyla/1.jpeg";
import MistressK2 from "../assets/images/models/Mistress Keyla/2.jpeg";
import MistressK3 from "../assets/images/models/Mistress Keyla/3.jpeg";
import MistressK4 from "../assets/images/models/Mistress Keyla/4.jpeg";
import MistressK5 from "../assets/images/models/Mistress Keyla/5.jpeg";
import Anastasia from "../assets/images/models/Anastasia/1.jpeg";
import Anastasia2 from "../assets/images/models/Anastasia/2.jpg";
import Anastasia3 from "../assets/images/models/Anastasia/3.jpeg";
import Anastasia4 from "../assets/images/models/Anastasia/4.jpg";
import Anastasia5 from "../assets/images/models/Anastasia/5.jpg";
import Anastasia6 from "../assets/images/models/Anastasia/6.jpg";
import Pillar from "../assets/images/models/Pillar/1.jpeg";
import Pillar2 from "../assets/images/models/Pillar/2.jpeg";
import Pillar3 from "../assets/images/models/Pillar/3.jpg";
import Pillar4 from "../assets/images/models/Pillar/4.jpeg";
import Pillar5 from "../assets/images/models/Pillar/5.jpeg";
import Pillar6 from "../assets/images/models/Pillar/6.jpeg";
import Zendaia from "../assets/images/models/Zendaia/1.jpeg";
import Zendaia2 from "../assets/images/models/Zendaia/2.jpg";
import Zendaia3 from "../assets/images/models/Zendaia/3.jpg";
import Zendaia4 from "../assets/images/models/Zendaia/4.jpg";
import Zendaia5 from "../assets/images/models/Zendaia/5.jpg";
import Zendaia6 from "../assets/images/models/Zendaia/6.jpeg";
import Julie from "../assets/images/models/Julie/1.jpeg";
import Julie2 from "../assets/images/models/Julie/2.jpeg";
import Julie3 from "../assets/images/models/Julie/3.jpeg";
import Julie4 from "../assets/images/models/Julie/4.jpeg";
import Julie5 from "../assets/images/models/Julie/5.jpeg";
import Julie6 from "../assets/images/models/Julie/6.jpeg";
import Aria from "../assets/images/models/Julie/1.jpeg";
import Aria2 from "../assets/images/models/Aria/2.jpeg";
import Aria3 from "../assets/images/models/Aria/3.jpeg";
import Aria4 from "../assets/images/models/Aria/4.jpeg";
import Aria5 from "../assets/images/models/Aria/5.jpeg";
import Aria6 from "../assets/images/models/Aria/6.jpg";
import Belmira from "../assets/images/models/Belmira/1.jpeg";
import Belmira2 from "../assets/images/models/Belmira/2.jpeg";
import Belmira3 from "../assets/images/models/Belmira/3.jpeg";
import Belmira4 from "../assets/images/models/Belmira/4.jpeg";
import Belmira5 from "../assets/images/models/Belmira/5.jpeg";
import Belmira6 from "../assets/images/models/Belmira/6.jpeg";

const Home = () => {
  const nav = useNavigate();
  const models = [
    // {
    //   img: MiaM,
    //   name: "Mia Melano",
    //   age: "24 years",
    //   spl: "Blonde and Natural",
    // , wpLink: "", tgLink: "" },
    {
      img: Elizabeth,
      name: "Elizabeth",
      age: "99 years",
      spl: "Eastern European",
      desc: "The last time that Elizabeth was in London she was a massive hit! Completely gorgeous and oozing sex appeal this busty 27-year-old mature London escorts is back with a very large bang - and you can see by her reviews that people are loving her to bits! And what is there not to love when a woman is this hot???",
      proImg: [Elizabeth2, Elizabeth3, Elizabeth4, Elizabeth5, Elizabeth6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Valeria,
      name: "Valeria",
      age: "30 years",
      spl: "Eastern European",
      desc: "Valeria is a very pretty blonde listed in our mature gallery and some other sexy ladies. You can book time with her for an incall or an outcall. Valeria is 30 years old but has the body of a 25-year-old, she is very busty, and her DD boobs are natural.",
      proImg: [Valeria2, Valeria3, Valeria4, Valeria5, Valeria6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Gabby,
      name: "Gabby",
      age: "26 years",
      spl: "Brazilian",
      desc: "Gabby is a beautiful brunette with brown eyes and a breathtaking figure. She is petite yet curvy and perfectly balances between being sexy and charming, stunning and cute. Gabby ticks many boxes, especially if you like a passionate and energetic woman in the bedroom.",
      proImg: [Gabby2, Gabby3, Gabby4, Gabby5, Gabby6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Avery,
      name: "Avery",
      age: "22 years",
      spl: "Eastern European",
      desc: "Avery is the real deal, read this sexy escorts profile and then pick up the phone to make your booking with this lady of your dreams. Avery is brunette, she is 22 years old, fully bisexual and as naughty as naughty can be, she really enjoys what she does and takes a great deal of pride in it.",
      proImg: [Avery2, Avery3, Avery4, Avery5, Avery6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Alma,
      name: "Alma",
      age: "23 years",
      spl: "Eastern European",
      desc: "Sexy 23-year-old blonde London escort Alma is beautiful; she has a great figure and always dresses to impress. Her trim waist offset her toned bum and amazing legs – this lady is the perfect foot fetish escort.",
      proImg: [Alma2, Alma3, Alma4, Alma5, Alma6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: LuciaAnne,
      name: "Lucia-Anne",
      age: "22 years",
      spl: "Eastern European",
      desc: "At 22, Lucia-Anne combines her stunning looks, slim figure, and natural 36B breasts to create an unforgettable experience. With a passion for presentation and an exciting collection of toys and uniforms, Lucia-Anne is based in Baker Street for incalls and is also available for outcalls.",
      proImg: [LuciaAnne2, LuciaAnne3, LuciaAnne4, LuciaAnne5, LuciaAnne6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Devon,
      name: "Devon",
      age: "25 years",
      spl: "Eastern European",
      desc: "Devon is a striking 23 year old brunette escort from Eastern Europe, she is fun and full of energy – she loves what she does and classes this as her favorite past time. She is very friendly – loving to wine and dine with friends, providing a warm and relaxing atmosphere for anyone that she has around her, to loves to be looked after but also to look after people.",
      proImg: [Devon2, Devon3, Devon4, Devon5, Devon6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Reese,
      name: "Reese",
      age: "29 years",
      spl: "Eastern European",
      desc: "Reese is a very beautiful and striking Eastern European escort available for both incalls and outcalls via our escort agency.You will find this amazing lady in Marylebone for incalls  you can also book her for an outcall – this is when she comes to yours.",
      proImg: [Reese2, Reese3, Reese4, Reese5, Reese6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Lindsey,
      name: "Lindsey",
      age: "28 years",
      spl: "Eastern European",
      desc: "Lindsey is one of the nicest ladies that you will meet here at Dior Escorts, a real gem in our crown and she can be booked for both incalls and outcalls right now. At 22 years old Lindsey has a sexy tight figure with wonderful 34C boobs – which she of course loves to show off.",
      proImg: [Lindsey2, Lindsey3, Lindsey4, Lindsey5, Lindsey6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Debra,
      name: "Debra",
      age: "27 years",
      spl: "Brazilian",
      desc: "Debra is a very sexy Brazilian escort with long dark hair and sexy come-to-bed eyes; she has the perfect figure with curves where they should be, a busty escort who is sure to be everyone's favourite. Located in Earls Court for incalls and available day and night, Debra is certain to make a name for herself and has to be one of the sexiest escorts in London at the moment.",
      proImg: [Debra2, Debra3, Debra4, Debra5, Debra6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Tessa,
      name: "Tessa",
      age: "21 years",
      spl: "Eastern European",
      desc: "A stunning 21-year-old brunette who has a fantastic figure – you can understand why she loves to dress up, she has a range of likes that cover most bases; foot fetish and roleplay with Tessa are very popular.",
      proImg: [Tessa2, Tessa3, Tessa4, Tessa5, Tessa6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Charlie,
      name: "Charlie",
      age: "22 years",
      spl: "Eastern European",
      desc: "Charlie is a stunning young blonde escort based in Kensington, who has 34DD boobs, is extremely pretty, and, as you can see from her likes list, is very naughty and lots of fun to be around. Charlie lives in a beautiful apartment, a great place to relax and unwind.",
      proImg: [Charlie2, Charlie3, Charlie4, Charlie5, Charlie6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Ellie,
      name: "Ellie",
      age: "24 years",
      spl: "Eastern European",
      desc: "Ellie is 24 years old; she is a very sweet and playful young lady who looks forward to every day. A romantic at heart, Ellie can be booked for a dreamy GFE type of date in which you will feel the weight of the world lifted from your shoulders! A very girly girl Ellie likes the finer things in life and will reward those who treat her in this way.",
      proImg: [Ellie2, Ellie3, Ellie4, Ellie5, Ellie6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Kelly,
      name: "Kelly",
      age: "27 years",
      spl: "Eastern European",
      desc: "Getting to Kelly is simple – she is a few minutes’ walk from Earls Court tube station, which is served by the Circle and District lines, she is only a 5 minute journey away from Gloucester Road  station which makes her a great choice of anyone in that area. There is on street parking close by, but this is on a first come first served basis so please don’t hold your breath.",
      proImg: [Kelly2, Kelly3, Kelly4, Kelly5, Kelly6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Beauty,
      name: "Beauty",
      age: "22 years",
      spl: "Eastern European",
      desc: "Beauty is a naturally busty brunette 22-year-old babe from Eastern Europe. With her sexy look and come-to-bed eyes, this beautiful young lady will have you eating out of her hands. She has an assortment of sexy outfits – some of which you can see in her photos. Clients love her dressed up as a secretary, and she has a wardrobe filled with high heels that will make you drool.",
      proImg: [Beauty2, Beauty3, Beauty4, Beauty5, Beauty6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Hailey,
      name: "Hailey",
      age: "22 years",
      spl: "Eastern European",
      desc: "You will see that Hailey has spent a lot of time and energy making sure her profile is perfect, along with a great selection of professional photos this very sexy 21-year-old escort always has new selfies and new videos for you to enjoy!",
      proImg: [Hailey2, Hailey3, Hailey4, Hailey5, Hailey6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Riley,
      name: "Riley",
      age: "25 years",
      spl: "Eastern European",
      desc: "Riley is a sexy brunette escort you can make a booking with. Riley loves to dress up to show off that very trim figure; you will see that this sexy London escort has a vast choice of outfits – look at her photos to get an idea of how great she looks.",
      proImg: [Riley2, Riley3, Riley4, Riley5, Riley6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Rizzo,
      name: "Rizzo",
      age: "25 years",
      spl: "Latvian",
      desc: "Rizzo is an incredible-looking blonde lady with an impressive natural figure, 36D boobs, a very sexy smile and who is also very open-minded and playful. If great-looking, open-minded, and playful ladies are your thing, then you have no need to look any further. If not, then check out Devon, a very naughty, playful, and open-minded brunette!",
      proImg: [Rizzo2, Rizzo3, Rizzo4, Rizzo5, Rizzo6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Clary,
      name: "Clary",
      age: "22 years",
      spl: "Eastern European",
      desc: "Clary is a flawless beauty, a buxom blonde whom can be booked daily via our escort agency.  She is a utter beauty with silky smooth skin, her boobs shine out – they are amazing and if you are a boob men they are what you really need! Her kissable lips will draw you in.It is ladies like Clary that make the escorts in London amongst the very best in the whole wide world!",
      proImg: [Clary2, Clary3, Clary4, Clary5, Clary6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Aylin,
      name: "Aylin",
      age: "29 years",
      spl: "Eastern European",
      desc: "Aylin is a gorgeous, naughty and sexy escort available to book for incalls and outcalls. If you want to spend time with a very sexy and open-minded escort, then Aylin needs to be at the top of your list; not only is Aylin fun ( this babe loves to party), but she also loves to dress up and play out some naughty scenarios.",
      proImg: [Aylin2, Aylin3, Aylin4, Aylin5, Aylin6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: EmilyJane,
      name: "Emily-Jane",
      age: "21 years",
      spl: "Eastern European",
      desc: "Emily-Jane is a very beautiful 21-year-old escort, she comes from Eastern Europe but speaks very good English. Emily-Jane has a great figure, she is a natural 34D bust, lovely legs and very sexy feet, she loves to roleplay and to experiment and is one of the most open minded “new’ escorts to arrive in London.",
      proImg: [EmilyJane2, EmilyJane3, EmilyJane4, EmilyJane5, EmilyJane6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Kacie,
      name: "Kacie",
      age: "21 years",
      spl: "Eastern European",
      desc: "Stunning blonde escort Kacie is now available in the Dior Escorts gallery; a super sweet and sexy young lady, book Kacie for both incalls and outcalls. Kacie is beautiful and has a natural figure; she stands at 5ft7 without heels and always loves to wear sexy lingerie.",
      proImg: [Kacie2, Kacie3, Kacie4, Kacie5, Kacie6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Bishop,
      name: "Bishop",
      age: "26 years",
      spl: "Eastern European",
      desc: "Read on to find out more about this sexy 26-year-old brunette escort from Eastern Europe who you can book for either an incall or an outcall. She is very friendly and very willing; she loves to have fun and really enjoys what she does – roleplay is amongst the favourites of her likes or although she does give a pretty amazing gfe session.",
      proImg: [Bishop2, Bishop3, Bishop4, Bishop5, Bishop6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Nina,
      name: "Nina",
      age: "26 years",
      spl: "Spanish",
      desc: "Nina is very open minded, you will see that she has a very long list of likes, you will see that she loves to dress up and from her very sexy selfies you will see that she looks dam hot! Nina is from Spain, she speaks perfect English – we really do love a sexy accent here at our agency, she is tall with a very slender figure, has a few sexy tattoos in some very hard to find places, loves wearing high heels and loves to show off her beautiful body!",
      proImg: [Nina2, Nina3, Nina4, Nina5, Nina6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Francesca,
      name: "Francesca",
      age: "25 years",
      spl: "Brazilian",
      desc: "With her magnetic personality, breathtakingly curvaceous figure, and luscious blonde mane, Francesca embodies sultry elegance. As an escort with a touch of exotic allure, she promises an unforgettable experience that excites the senses. Combine her naughtiness, good looks, and enviable hourglass curves, she exudes an aura of pure seduction that leaves admirers in awe.",
      proImg: [Francesca2, Francesca3, Francesca4, Francesca5, Francesca6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Marya,
      name: "Marya",
      age: "20 years",
      spl: "Eastern European",
      desc: "Marya is a gorgeous 20-year-old brunette escort from Eastern Europe. She is a great-looking young lady with a terrific figure and is available for incalls and outcalls. Marya is not the most open-minded escort, but if you’re looking to meet a fun and friendly lady, she is a top choice. Should you wish to meet a very open-minded escort, Sunshine is a top choice.",
      proImg: [Marya2, Marya3, Marya4, Marya5, Marya6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Paola,
      name: "Paola",
      age: "31 years",
      spl: "Eastern European",
      desc: "Oh yes! This lady is something special, and she is available here now at Dior Escorts Agency in London, home of some of the most beautiful escorts in the whole of London! Paola is 31 years young, has a body that most women would kill for, eyes that hypnotise you and a bum that you just want to bury your head in!",
      proImg: [Paola2, Paola3, Paola4, Paola5, Paola6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Lottie,
      name: "Lottie",
      age: "20 years",
      spl: "Eastern European",
      desc: "This very sexy young blonde escort is based in Edgware Road, W@ – should you visit to visit her for an incall, or she will come to visit you at yours (that’s an outcall). You can see from Lottie’s photos that she has a very sexy look, a great body but what you can’t see is the fact that she is also very friendly.",
      proImg: [Lottie2, Lottie3, Lottie4, Lottie5, Lottie6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Martini,
      name: "Martini",
      age: "22 years",
      spl: "Eastern European",
      desc: "Would you like to see an escort in the Bayswater area? Well, you have landed on the correct page if you do because that is where Martini is based and where you can visit this perfect brunette escort for an incall! A very pretty and young blonde Martini comes across as a very warm-hearted young lady with a very naughty side to her – her belly is filled with fire and passion.",
      proImg: [Martini2, Martini3, Martini4, Martini5, Martini6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Bambi,
      name: "Bambi",
      age: "28 years",
      spl: "Eastern European",
      desc: "Bambi is a fully bi stunning brunette who joins us from Eastern Europe, she is 28 years old with gives her the experiance and finest that so many of you seek, she really does know how to please does our Bambi. Bambi is an expert at all things domination and you will bow down to her sexy painted red toe nails if she tells you to! My days are you going to have some fun with this young lady!",
      proImg: [Bambi2, Bambi3, Bambi4, Bambi5, Bambi6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Winter,
      name: "Winter",
      age: "23 years",
      spl: "Eastern European",
      desc: "23-year-old stunner Winter comes from Brazil and is beautiful; her figure is curvy – her boobs and bum will blow you away; she has a very sexy look topped off with come-to-bed smoky eyes.  Winter lists some of the most popular “likes,” which include dfk and parties.",
      proImg: [Winter2, Winter3, Winter4, Winter5],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Larisss,
      name: "Larisss",
      age: "23 years",
      spl: "Eastern European",
      desc: "Larisss is a charming and sexy brunette escort you can visit via this escort agency. An amiable and intelligent young lady – she is 23, Larisss speaks basic English but is learning all the time. She also speaks German and French.Openminded and playful are great ways to describe this young babe; check out her list of likes to see why.",
      proImg: [Larisss2, Larisss3, Larisss4, Larisss5, Larisss6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Cameo,
      name: "Cameo",
      age: "22 years",
      spl: "Brazilian",
      desc: "22-year-old ebony Brazilian escort Cameo is based in Earls Court for incalls and can also be booked for outcalls. A very sexy ebony escort who beautiful breasts, and a very sexy arse, Cameo is the dream lady for so many. Naturally beautiful, Cameo is very sexy and loves a classic, elegant style. Her smile lights up when she meets new people.",
      proImg: [Cameo2, Cameo3, Cameo4, Cameo5, Cameo6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Pamela,
      name: "Pamela",
      age: "30+ years",
      spl: "Eastern European",
      desc: "If you want an escort that looks just like her photographs, look no further than our gorgeous Pamela. She’s in her 30s and one of our more mature escorts, so she really knows what she’s doing in the bedroom. Regardless of whether you are looking for a romantic dinner date or some bisexual fun, Pamela is the woman for you.",
      proImg: [Pamela2, Pamela3, Pamela4, Pamela5, Pamela6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Rosaline,
      name: "Rosaline",
      age: "29 years",
      spl: "Russian",
      desc: "One of the hottest babes to join our ranks, with a killer body, sexy natural 34C boobs ( busty escorts are very popular at the moment), lovely long sexy and very sexy feet – the perfect companion for those of you into foot play! Rosaline has a very long list of likes, classing her as open-minded and fun; she will help you live out some of your fantasies.",
      proImg: [Rosaline2, Rosaline3, Rosaline4, Rosaline5, Rosaline6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Jennifer,
      name: "Jennifer",
      age: "24 years",
      spl: "Asian",
      desc: "A fun and energetic Asian lady Jennifer is sure to be a hit with anyone looking for some companionship while in this great city of ours. ennifer has amazing skin, beautiful hair and always looks so lovely -she is a dream come true for anyone who is looking for a very intelligent lady.",
      proImg: [Jennifer2, Jennifer3, Jennifer4, Jennifer5, Jennifer6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Mistress,
      name: "Mistress Bianca",
      age: "24 years",
      spl: "Eastern European",
      desc: "Her mastery of the art of domination is unparalleled, and those who willingly submit to her authority find themselves ensnared in a world where pleasure and pain dance on a fine line. Mistress Bianca takes pride in delivering the most exquisite punishments, ensuring that her slaves experience the perfect blend of agony and ecstasy.",
      proImg: [Mistress2, Mistress3, Mistress4, Mistress5, Mistress6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressC,
      name: "Mistress Chloe",
      age: "23 years",
      spl: "Eastern European",
      desc: "Mistress Chloe’s repertoire includes an enticing array of activities designed to fulfil your fantasies: face Sitting, pegging, humiliation, slapping (give), boot worship & foot fetish, light domination, whipping (give), cbt (Cock and Ball Torture), spanking (give)",
      proImg: [MistressC2, MistressC3, MistressC4, MistressC5],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressR,
      name: "Mistress Reese",
      age: "29 years",
      spl: "Eastern European",
      desc: "Mistress Reese has many dildos, strapons, whips, cuffs, beads and more to make your session more enjoyable. Mistress Reese has a gorgeous apartment you may be lucky to be allowed in. A very strict woman awaits you when you make your booking with Mistress Reese. Mistress Reese will tell you when she is available; you need to call and ask.",
      proImg: [MistressR2, MistressR3, MistressR4, MistressR5, MistressR6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressE,
      name: "Mistress Erika",
      age: "22 years",
      spl: "Eastern European",
      desc: "This very sexy and powerful woman is one of the hottest Mistresses that you will meet, she has the perfect skill set for both novices and experienced subs – have you ever been pegged before? You can either meet her at her place or she will happily book a full dungeon experience for you (there will be an extra cost for this)",
      proImg: [MistressE2, MistressE3, MistressE4, MistressE5, MistressE6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressZ,
      name: "Mistress Zoey",
      age: "23 years",
      spl: "Eastern European",
      desc: "Mistress Zoey is very strict and can inflect pain if you are a naughty boy – you wouldn’t want to be a naughty boy now, would you? Perhaps you would! Perhaps you want the Mistress to have you knee on the floor with her foot on your head making you link clean her sexy boots? Experience the full-on London dominatrix experience with Mistress. Zoey in a safe environment.",
      proImg: [MistressZ2, MistressZ3, MistressZ4, MistressZ5, MistressZ6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressB,
      name: "Mistress Belle",
      age: "28 years",
      spl: "European",
      desc: "Her expertise spans from intimate, focused massages like the prostate massage, yoni massage, and the profoundly stimulating four-hands massage to more immersive experiences such as tie-and-tease, body-to-body, relaxing, bamboo stick, foot, extra lingam (for multiple orgasms), mutual, Swedish, and voyeur massage.",
      proImg: [MistressB2, MistressB3, MistressB4, MistressB5, MistressB6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressV,
      name: "Mistress Villa",
      age: "22 years",
      spl: "Eastern European",
      desc: "Mistress Villa loves to dress to impress and does such in her sessions; this sexy dominatrix has a host of outfits that include a catsuit, tight boots, a latex dress, a PVC dress, a PVC body suit, latex gloves, a leather skirt, a leather trousers, PVC corset, schoolgirl, nurse and secretary outfits.",
      proImg: [MistressV2, MistressV3, MistressV4, MistressV5, MistressV6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: MistressK,
      name: "Mistress Keyla",
      age: "24 years",
      spl: "European",
      desc: "From the moment you see Mistress Keyla dressed in one of her many sexy outfits, you will see what all the fuss is about. She is a stunningly beautiful blonde with brown eyes and a perfect bust. From head to toe, Mistress Keyla is gorgeous.",
      proImg: [MistressK2, MistressK3, MistressK4, MistressK5],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Anastasia,
      name: "Anastasia",
      age: "25 years",
      spl: "French",
      desc: "When you choose to see Anastasia, you choose a woman who knows how to have a good time. Whether you are heading out together for a romantic dinner or partying your way into the early morning hours, Anastasia is the perfect companion. She’s also the ideal woman to take to a business function or corporate dinner, and she will impress your colleagues with her charm and charisma.",
      proImg: [Anastasia2, Anastasia3, Anastasia4, Anastasia5, Anastasia6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Pillar,
      name: "Pillar",
      age: "23 years",
      spl: "Brazilian",
      desc: "A very elegant young lady, Pillar speaks Portuguese, English, and Spanish—she has a very sexy accent. On her profile, Pillar has listed some of the more popular likes, including gfe and owo—very popular. For couples looking to add a third to their playful evening, Pillar is a great choice, as she is fully bisexual and loves to play.",
      proImg: [Pillar2, Pillar3, Pillar4, Pillar5, Pillar6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Zendaia,
      name: "Zendaia",
      age: "28 years",
      spl: "European",
      desc: "Zendaia is a high-class blonde escort with a fantastic figure and a very open mind. Zendaia is beautiful, elegant and sexy. She is very naughty! Add all these elements together and get the perfect high-class London escort. Zendaia can meet with gentlemen, ladies, and couples (ffm). She is fully bisexual and loves to play.",
      proImg: [Zendaia2, Zendaia3, Zendaia4, Zendaia5, Zendaia6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Julie,
      name: "Julie",
      age: "25 years",
      spl: "Eastern European",
      desc: "Having a fun time with Julie is very easy to do, this sexy blonde London escort is very pleasurable and easy to be with. If you are looking for a dominatrix experience with a escort then Julie is also a very good choice. From her pictures you will see that she has all the “gear’ for these types of bookings and also a very outgoing mind.",
      proImg: [Julie2, Julie3, Julie4, Julie5, Julie6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Aria,
      name: "Aria",
      age: "24 years",
      spl: "Eastern European",
      desc: "Aria is a 24-year-old blonde stunning who has an incredible figure with natural boobs – a perfect choice for those of you who love meeting sexy busty escorts. Aria speaks perfect English with a very sexy accent. She is fun, friendly, and very open-minded. Look at that long list of likes; you’re sure to find something you want to experiment with.",
      proImg: [Aria2, Aria3, Aria4, Aria5, Aria6],
      wpLink: "",
      tgLink: "",
    },
    {
      img: Belmira,
      name: "Belmira",
      age: "21 years",
      spl: "Brazilian",
      desc: "Belmira is a high-class escort available to book time with via this escort agency website. A very sophisticated escort, Belmira is just 21 years old. An experienced and skilful seductress, Belmira knows exactly what it takes to press a man’s buttons and show him the time of his life. Sexy and stylish are the words that Belmira uses to describe herself.",
      proImg: [Belmira2, Belmira3, Belmira4, Belmira5, Belmira6],
      wpLink: "",
      tgLink: "",
    },
  ];
  const [postPerPage, setPostPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = postPerPage * currentPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = models
    ? models.slice(indexOfFirstPost, indexOfLastPost)
    : null;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(models.length / postPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleNext = () => {
    if (pageNumbers.includes(currentPage + 1)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrev = () => {
    if (pageNumbers.includes(currentPage - 1)) {
      setCurrentPage(currentPage - 1);
    }
  };
  const profilesRef = useRef(null);
  const handleScrollToProfiles = () => {
    profilesRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div style={{ background: "black", color: "white" }}>
      <div className="caro-img">
        <div className="dumy"></div>
        <div className="caro-content">
          <h1 className="caro-h">AUESCORTS</h1>
          <p className="caro-p">
            These girls are ideal for any occasion including dinner dates,
            social gatherings and quiet intimate relationships where
            discretion is guaranteed
          </p>
          <button className="book-now" type="submit">
            Book Now
          </button>
        </div>
      </div>
      <div className="profiles" ref={profilesRef}>
        <div className="d-flex">
          <h2 className="font-h">Top Profiles</h2>
          <div className="mx-5" style={{ marginTop: "-5px" }}>
            <p className="font-m">All Profiles</p>
            <img src={arrow} alt="" style={{ marginTop: "-40px" }} />
          </div>
        </div>
        <div className="models-list row">
          {currentPosts.map((e, i) => (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-3"
              key={i}
              style={{ cursor: "pointer" }}
              onClick={() =>
                nav("/profile", {
                  state: {
                    name: e.name,
                    age: e.age,
                    spl: e.spl,
                    desc: e.desc,
                    proImg: e.proImg,
                    wpLink: e.wpLink,
                    tgLink: e.tgLink,
                  },
                })
              }
            >
              <img className="model-img" src={e.img} alt="" />
              <h5 className="font-h mt-3">{e.name}</h5>
              <p className="font-m" style={{ fontSize: "0.7rem" }}>
                {e.age}, {e.spl}
              </p>
            </div>
          ))}
        </div>
        <nav className="pagination-num d-flex justify-content-center mt-3">
          <ul className="pagination">
            <MdKeyboardArrowRight
              className="mt-1 mx-2 fs-2"
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
              onClick={() => {
                handlePrev();
                handleScrollToProfiles();
              }}
            />
            {pageNumbers.map((e) => (
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 7px",
                  backgroundColor:
                    e === currentPage
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(255, 255, 255, 0.3)",
                }}
                key={e}
                className="page-item"
              >
                <a
                  className="p-2 fs-5"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    border: "none",
                  }}
                  onClick={() => {
                    setCurrentPage(e);
                    handleScrollToProfiles();
                  }}
                  href=""
                >
                  {e}
                </a>
              </Link>
            ))}
            <MdKeyboardArrowRight
              className="mt-1 mx-2 fs-2"
              onClick={() => {
                handleNext();
                handleScrollToProfiles();
              }}
              style={{ cursor: "pointer" }}
            />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Home;
